/**
 * @desc encapsulation axios
 */

import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import tools from '@/utils'
import router from '@/router'

const requestTimeOut = 1000 * 60
const baseUrl = process.env.VUE_APP_BASE_URL
/**
 * @class
 * @classdesc encapsulation axios
 */
class Request {
  constructor(baseURL) {
    this.baseURL = baseURL
  }
  getInsideConfig() {
    const config = {
      baseURL: this.baseURL,
      headers: {}
    }
    return config
  }
  interceptors(instance) {
    // handle request
    instance.interceptors.request.use((config) => {
      // form format
      if (config.headers['Content-Type'] == 'application/x-www-form-urlencoded') {
        config.data = config.data ? qs.stringify(config.data) : config.data
      }

      // add token
      let token = tools.token
      if (token) {
        config.headers['token'] = token
      }
      return config
    })

    // handle response
    instance.interceptors.response.use(
      (res) => {
        // refresh token
        const { headers } = res
        if (headers['refresh-token']) {
          tools.token = headers['refresh-token']
        }

        // normal
        return res.data
      },
      (error) => {
        // handle response error
        if (error && error.response) {
          // switch (error.response.status) {
          //     // token expired
          //     case 401:
          //         break
          //     default:
          // }
          return Promise.reject(error.response)
        }
        return Promise.reject(error)
      }
    )
  }
  request(options) {
    // create axios instance object
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance)
    return instance(options)
  }
}

/**
 * @class
 * @classdesc encapsulation request function
 */
class Http {
  constructor(axios) {
    this.axios = axios
  }

  /**
   * @param {object} options
   * @example {
   *    baseURL: 'base url',
   *    data: 'request body',
   *    params: 'query params',
   *    url: 'api',
   *    method: 'request method',
   *    headers: 'request header',
   *    responseType: 'response type'
   * }
   * @param {boolean} showError to show error msg by default
   * @returns {AxiosPromise}
   */
  handleRequest(options, showError) {
    const { responseType } = options
    // determine whether it is in other response body format
    let flag = responseType
    return new Promise((resolve, reject) => {
      this.axios
        .request(options)
        .then((res) => {
          let timeOut = 0
          // it is in other format
          if (flag === 'blob') {
            if (res.type === 'application/json') {
              timeOut = 300
              let reader = new FileReader()
              reader.onload = function () {
                let readerres = reader.result
                res = JSON.parse(readerres)
              }
              reader.readAsText(res, 'utf-8')
            } else {
              resolve(res)
              return
            }
          }

          // Wait for new FileReader().onload execution to complete
          setTimeout(() => {
            // it is normal，check whether the response body is a standard  json
            let temp = res
            if (typeof res === 'string') {
              try {
                temp = JSON.parse(res)
              } catch (error) {
                temp = {
                  code: 'no-json',
                  msg: 'Failed to convert JSON. The back end returned a non-standard JSON string!'
                }
              }
            }
            // perform different operations according to the code
            const { $t } = store.state.vm
            let { code, msg } = temp

            if (!code) {
              tools.msg({
                msg,
                type: 'error'
              })
              return
            }

            if (code === 200) {
              resolve(res)
            } else if (code === 401) {
              if (router['currentRoute'].name != 'login') {
                tools.msg({
                  msg: $t('error.userExpired'),
                  type: 'info',
                  duration: 1500
                })
                const redirectPath = `?redirectPath=${encodeURIComponent(
                  router['currentRoute'].fullPath
                )}`
                router.replace(`/login/form${redirectPath}`)
              }
              reject(res)
            } else if (code === 600040) {
              // password expired, so must go to change a new password
              reject(res)
            } else {
              if (showError) {
                // todo: 报错
                tools.msg({
                  msg,
                  type: 'error'
                })
              }
              reject(res)
            }
          }, timeOut)
        })
        .catch((err) => {
          if (err.request.readyState == 4 && err.request.status == 0) {
            const { $t } = store.state.vm
            tools.msg({
              msg: $t('error.timeOut'),
              type: 'error',
              duration: 5000
            })
          }
          reject({
            code: err.status,
            data: err.data
          })
        })
    })
  }

  get(options, showError = true) {
    const params = {
      method: 'GET',
      timeout: requestTimeOut,
      ...options
    }
    return this.handleRequest(params, showError)
  }

  post(options, showError = true) {
    const params = {
      method: 'POST',
      timeout: requestTimeOut,
      ...options
    }
    return this.handleRequest(params, showError)
  }
}

export default new Http(new Request(baseUrl))
