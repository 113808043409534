<template>
  <div v-if="visible">
    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape"
      :show-close="showClose"
      width="420px"
      @close="handleClose"
    >
      <p>{{ msg }}</p>
      <div slot="footer">
        <el-button
          v-if="cancelBtnTxt"
          @click="handleCancel"
          >{{ cancelBtnTxt }}</el-button
        >
        <el-button
          v-if="confirmBtnTxt"
          type="primary"
          @click="handleConfirm"
          >{{ confirmBtnTxt }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dialogEventType } from './const.js'
// import { mapState } from 'vuex'

export default {
  props: {
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    msg: {
      type: String,
      default: ''
    },
    confirmBtnTxt: {
      type: String,
      default: ''
    },
    cancelBtnTxt: {
      type: String,
      default: ''
    },
    callback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false
    }
  },
  mounted() {},
  methods: {
    // close， cancel， confirm统一调用这个事件
    handleEvent({ eventType }) {
      const { callback } = this
      this.visible = false
      callback({ eventType })
    },
    handleClose() {
      this.handleEvent({ eventType: dialogEventType.close })
      // document.removeEventListener('keyup', this.handleEnter)
    },
    handleCancel() {
      this.handleEvent({ eventType: dialogEventType.cancel })
    },
    handleConfirm() {
      this.handleEvent({ eventType: dialogEventType.confirm })
      // document.removeEventListener('keyup', this.handleEnter)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-dialog {
  .van-dialog__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32.5px 0;
    box-sizing: border-box;
    img {
      width: 43px;
      height: 43px;
    }

    & > span {
      margin: 16px 0 10px 0;
      font-size: 24px;
      line-height: 24px;
      font-weight: 500;
      color: #131414;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #909399;
    }
  }
}
::v-deep .el-dialog {
  margin-top: 30vh !important;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32.5px 0;
    box-sizing: border-box;

    img {
      width: 63px;
      height: 63px;
    }

    & > span {
      margin: 17.5px 0 4px 0;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: $themeFontColor;
    }

    p {
      padding: 0 20px;
      font-size: 14px;
      line-height: 24px;
      color: $auxiliaryFontColor;
    }
  }
  .el-dialog__footer {
    & > div {
      .el-button {
        height: 32px;
        font-size: 14.5px;
      }
    }
  }
}
</style>
