/**
 * @desc import element-ui components on demand
 */

import Vue from 'vue'
import enLocale from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import {
  Loading,
  Message,
  MessageBox,
  Dialog,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxGroup,
  Button,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Table,
  TableColumn,
  Pagination,
  Select,
  OptionGroup,
  Option,
  DatePicker,
  Tag,
  Cascader,
  Popconfirm,
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  Switch,
  Link,
  Row,
  Col,
  Tooltip,
  Progress,
  Upload,
  Slider,
  Steps,
  Step,
  Popover,
  RadioGroup,
  Radio,
  RadioButton
} from 'element-ui'
const _Loading = Loading
const { directive: loadingDirective, service: loadingService } = _Loading

locale.use(enLocale)

Vue.use(loadingDirective)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Button)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Select)
Vue.use(OptionGroup)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Tag)
Vue.use(Cascader)
Vue.use(Popconfirm)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Switch)
Vue.use(Link)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tooltip)
Vue.use(Progress)
Vue.use(Upload)
Vue.use(Slider)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Popover)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(RadioButton)

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
Vue.prototype.$loading = loadingService
Vue.prototype.$message = Message
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
