import enLocale from 'element-ui/lib/locale/lang/en'
import menus from './menus'
import merchant from './merchant'
import account from './account'
import transactionCase from './transaction-case'
import dataCenter from './data-center'
import messageCenter from './message-center'
import {
  PAY_OUT,
  PAY_IN,
  MARKET_PLACE_ORDERS,
  US,
  BR,
  MX,
  CO,
  CL,
  PE,
  ZA,
  SPEI,
  SPEI_VA,
  SPEI_BANK_TRANSFER,
  TED,
  PIX,
  CARD,
  BOLETO,
  OXXO,
  GIFTCARD,
  TOPUP,
  UTILITY,
  PAY_CASH,
  PIC_PAY,
  PAYPAL,
  MERCADO_PAGO,
  AME_QR,
  CO_BANK_TRANSFER,
  CL_BANK_TRANSFER,
  PSE,
  NEQUI,
  BANCOLOMBIA_BUTTON,
  EFECTY,
  BANCOLOMBIA_COLLECTION,
  PIX_QR_CODE,
  MULTICAJA_TRANSFER,
  MULTICAJA_CASH,
  HITES,
  FPAY,
  CHEK,
  MACH,
  PAGO46,
  KHIPU,
  WEB_PAY,
  BUSINESS_TRANSACTION,
  BUSINESS_TOPUP,
  BUSINESS_REFUND,
  BUSINESS_TRANSFER_OUT,
  BUSINESS_EXCHANGE,
  BUSINESS_ADJUSTMENT,
  BUSINESS_SUPPLEMENT,
  DIRECTION_SETTLED,
  DIRECTION_REFUND,
  DIRECTION_REJECTED,
  DIRECTION_CHARGE_BACK,
  DIRECTION_CHARGE_BACK_REJECTED
} from '@/utils/constants'

export default {
  common: {
    done: 'Done',
    ok: 'OK',
    search: 'Search',
    add: 'Add',
    accountNumber: 'Account number',
    userName: 'User Name',
    role: 'Role',
    roleName: 'Role Name',
    terminal: 'Terminal',
    email: 'Email',
    larkUserId: 'Lark User Id',
    firstName: 'First Name',
    lastName: 'Last Name',
    status: 'Status',
    operation: 'Operation',
    modify: 'Modify',
    enable: 'Enable',
    disable: 'Disable',
    locked: 'Locked',
    delete: 'Delete',
    password: 'password',
    whole: 'Whole',
    confirm: 'Confirm',
    cancel: 'Cancel',
    errorTip: 'Please enter',
    noData: 'No data',
    success: 'Success',
    error: 'Error',
    remark: 'Remark',
    permissions: 'Permissions',
    operationTip: 'Are you sure to proceed? ',
    validateEmail: 'Please enter the correct email',
    validateAppendix: 'Please upload the attachment',
    adopt: 'Adopt',
    submit: 'Submit',
    refuse: 'Refuse',
    review: 'Review',
    minute: 'Minute',
    hour: 'Hour',
    date: 'Date',
    back: 'Back',
    account: 'Account',
    save: 'Save',
    edit: 'Edit',
    unlock: 'Unlock',
    loading: 'Loading',
    size: 'Size',
    amount: 'Amount',
    type: 'Type',
    approve: 'Approve',
    determine: 'Determine',
    download: 'Download',
    finish: 'Finish',
    now: 'Now',
    uploadFiles: 'Upload files',
    resetPassword: 'Reset Password',
    information: 'Information',
    details: 'Details',
    noMore: 'No more',
    transactionType: 'Transaction Type',
    [PAY_IN]: 'Payin',
    [PAY_OUT]: 'Payout',
    [MARKET_PLACE_ORDERS]: 'Marketplace',
    fee: 'Fee',
    tax: 'Tax',
    refresh: 'Refresh',
    overview: 'Overview',
    create: 'Create',
    close: 'Close',
    daily: 'Daily',
    monthly: 'Monthly',
    comparison: 'Comparison',
    copy: 'Copy',
    copySuccess: 'Copy successful',
    getLink: 'Get Link',
    reply: 'Reply',
    time: 'Time',
    case: 'Case',
    country: 'Country',
    transfer: 'Transfer',
    successfullyDeleted: 'Successfully deleted',
    normal: 'Normal',
    expired: 'Expired',
    skip: 'Skip',
    createTime: 'Creation Time',
    updateTime: 'Update Time',
    creator: 'Creator',
    updater: 'Updater',
    reset: 'Reset',
    vendor: 'Vendor',
    merchant: 'Merchant',
    id: 'Id',
    title: 'Title',
    detail: 'Detail',
    selectAll: 'Select All'
  },
  error: {
    userExpired: 'session expried, please login again!',
    backLogin: 'Back to login page',
    timeOut: 'Request Timeout'
  },
  pagination: {
    total: '{n} data items in total'
  },
  country: {
    [US]: 'US',
    [BR]: 'Brazil',
    [MX]: 'Mexico',
    [CO]: 'Colombia',
    [CL]: 'Chile',
    [PE]: 'Peru',
    [ZA]: 'South Africa'
  },
  langs: {
    en: 'English',
    zh: 'Chinese'
  },
  productType: {
    [SPEI]: 'SPEI',
    [SPEI_VA]: 'SPEI VA',
    [SPEI_BANK_TRANSFER]: 'SPEI(BANK TRANSFER)',
    [TED]: 'TED',
    [PIX]: 'PIX',
    [CARD]: 'Card',
    [BOLETO]: 'Boleto',
    [OXXO]: 'OXXO',
    [GIFTCARD]: 'Gift Card',
    [TOPUP]: 'Top Up',
    [UTILITY]: 'Utility',
    [PAY_CASH]: 'Cash',
    [PIC_PAY]: 'Pic Pay',
    [PAYPAL]: 'PAYPAL',
    [MERCADO_PAGO]: 'Mercado Pago',
    [AME_QR]: 'AME QR',
    [CO_BANK_TRANSFER]: 'CO Bank Transfer',
    [CL_BANK_TRANSFER]: 'CL Bank Transfer',
    [PSE]: 'PSE',
    [NEQUI]: 'NEQUI',
    [BANCOLOMBIA_BUTTON]: 'Bancolombia Button',
    [EFECTY]: 'EFECTY',
    [BANCOLOMBIA_COLLECTION]: 'BANCOLOMBIA COLLECTION',
    [PIX_QR_CODE]: 'PIX QR CODE',
    [MULTICAJA_TRANSFER]: 'Multicaja Transfer',
    [MULTICAJA_CASH]: 'Multicaja Cash',
    [HITES]: 'Hites',
    [FPAY]: 'Fpay',
    [CHEK]: 'Chek',
    [MACH]: 'Mach',
    [PAGO46]: 'Pago46',
    [KHIPU]: 'Khipu',
    [WEB_PAY]: 'WebPay'
  },
  search: {
    options: 'Options',
    inputPlaceholder: 'Input here',
    selectPlaceholder: 'Please select',
    monthPlaceholder: 'Select Month',
    yearPlaceholder: 'Select Year',
    datePlaceholder1: 'Start date',
    datePlaceholder2: 'End date',
    dateMonthsPlaceholder1: 'Start month',
    dateMonthsPlaceholder2: 'End month',
    theExportBtn: 'Export',
    h5Title: 'Filter'
  },
  menus,
  login: {
    login: 'Login',
    ICP: '©Copyright Liquido 2022. All rights reserved.',
    title: 'Sign in to your \n account',
    h5Title: 'Sign in to',
    noAccount1: 'No account?',
    noAccount2: 'Register a new account',
    placeholder: 'Enter account or email address',
    placeholder2: 'Enter email',
    placeholder3: 'Please input a password',
    placeholder4: 'Enter password',
    errorTip: 'Username is required',
    errorTip2: 'Password is required',
    errorTip3: 'Verification code is incorrect',
    errorTip4: 'Please Enter verification code',
    rememberPsw: 'Remember me',
    forgetPsw: 'Forget password?',
    signInTitle: 'Management System Platform',
    signInDesc: 'Welcome to the Merchant Management System',
    signIn2: 'SIGN IN',
    createNewPsw: 'Create a New Password',
    tip: 'A verification link will be sent to your email to create a new password. Please check it out.',
    tip2: 'Please fill in the form to get a new password.',
    tip3: 'Password must contain eight or more characters.',
    tip4: 'You can sign in to liquido platform with this new password',
    send: 'send out',
    ModifyPsw: '@:common.modify password',
    formItem: 'New password',
    formItem2: 'Confirm password',
    pswGrade: 'Strong password',
    resetSuccess: 'Modified successfully',
    exception: 'Login exception',
    policy1: 'By signing in, you agree to the ',
    policy2: '《Privacy Policy》',
    policy3: '. This site uses essential cookies, see our ',
    policy4: '《Cookie Notice》',
    policy5: ' for more information.',
    scanCode: 'Scan with Lark App'
  },
  resetPsw: {
    back2LoginPage: 'Back to login',
    pswAbout2ExpireOfCancelBtnTxt: 'Change later',
    pswAbout2ExpireOfConfirmBtnTxt: 'Change now',
    initialPsw: 'This account is for the first time, please reset your initial password.',
    pswAbout2Expire:
      'Your account password has not been changed for a long time and will lose its timeliness(expires in {pwdDaysRemaining} days). Please change your password as soon as possible.',
    pswExpired:
      'Your password has expired and must be changed! The current account has high risk. Change your password to continue logging in to the account.'
  },
  header: {
    logOut: 'Sign out',
    changePsw: 'Change password',
    oldPsw: 'Old password',
    newPsw: '@:login.formItem',
    modifySuccess: '@:login.resetSuccess',
    errorTip: '@:login.placeholder3',
    pswLength: 'at least 8 characters',
    pswReg: 'Including upper and lower case letters and numbers',
    confirmPsw: 'Please enter the same password as the new one'
  },
  asideBar: {
    changeTimezone: 'Change timezone'
  },
  access: {
    addUser: 'Add User',
    editUser: 'Edit User',
    addSuccess: 'successfully added',
    modifySuccess: 'Successfully modified',
    addRole: 'Add Role',
    editRole: 'Edit Role',
    emailTip:
      'We will send the initial account password to this email address. Please fill in the correct email address carefully.',
    sendPassword2Email: 'Get Password',
    sendPassword2EmailSuccess: 'The password has been sent to the mailbox:{email}',
    merchantCode: 'Merchant Code',
    userName: 'User Name',
    dashboard_inner_user_title: 'Supper User(Dashboard)'
  },
  case: {
    types: {
      transactions: 'Transactions',
      finance: 'Finance',
      balance: 'Balance',
      others: 'Others'
    },
    status: {
      pending: 'Pending',
      processing: 'Processing',
      solved: 'Solved',
      closed: 'Closed'
    },
    tableColumn1: 'Case ID',
    tableColumn2: 'Case Type',
    tableColumn4: 'Status',
    tableColumn5: 'Title',
    tableColumn6: 'Country',
    tableColumn7: 'Create Time',
    tableColumn8: 'Update Time',
    tableColumn9: 'Operation',
    tableColumn10: 'Product Type',
    tableColumn11: 'Merchant',
    tableColumn12: 'Sponsor',
    tableColumn13: 'Update handler',
    createBtn: 'Create New Case',
    formLabel1: 'Transaction Key',
    formLabel2: 'Appendix',
    formLabel3: 'Description',
    formLabel4: 'Vendor',
    formLabel5: 'Remark',
    formLabel6: 'E2E ID',
    formLabel7: 'Clabe Number',
    uploadTip: 'The size of a single attachment must not exceed 10M',
    viewDetail: 'View Detail',

    infoTitle1: 'Reply Information',
    infoTitle2: 'Case Information',
    infoReplyAsker: 'User',
    infoReplyResponder: 'Responder',
    infoReplyTime: 'Time',
    infoReplyQuestion: 'Question',
    infoReplyReply: 'Reply',

    operationProcess: 'Process',
    operationSolve: 'Solve',
    reopenLabel: 'Instructions',

    issueTitle: 'List',
    issueCreate: 'Create Issue',
    issueTableColumn1: 'Issue Buckets',
    issueTableColumn2: 'Definition',
    issueTableColumn3: 'Sort',
    editVendorWarning: 'Please fill in {a} and {b} first',
    solveWarning: 'Please fill in vendor first'
  },
  merchant,
  account,
  transactionCase,
  payment: {
    tableColumn1: 'Request ID',
    tableColumn2: 'Merchant',
    tableColumn3: 'Country',
    tableColumn4: 'Payment Channel',
    tableColumn5: 'Name',
    tableColumn6: 'Request Amount',
    tableColumn7: 'Status',
    tableColumn8: 'Create Time',
    status: {
      inProgress: 'In Progress',
      completed: 'Completed',
      rejected: 'Rejected',
      failed: 'Failed'
    },
    detail: {
      ownerTeam: 'Owner Team',
      updatedTime: 'Update Time',
      remark: 'Remark',
      proof: 'Proof of transfer',
      otherInfo: 'Payment Information'
    }
  },
  feeConfig: {
    productName: 'ProductName',
    settlementPeriod: 'SettlementPeriod',
    calculationRule: 'CalculationRule',
    sourceCurrency: 'SourceCurrency',
    transactionAmount: 'TransactionAmount',
    commissionFees: 'CommissionFees',
    byAmount: 'By <em>amount</em>',
    byCount: 'By <em>count</em>',
    taxFees: 'Tax',
    fxFees: 'FX',
    refundFees: 'REFUND_FEES',
    chargebackFees: 'CHARGE_BACK_FEES',
    min: 'min',
    max: 'max'
  },
  costConfig: {
    formTitle: 'Cost',
    tableColumn1: 'Merchant Code',
    tableColumn2: 'Country',
    tableColumn4: 'vendor',
    tableColumn5: 'product',
    tableColumn6: 'Active Month',
    costItem1: 'feeType',
    costItem2: 'feeOn',
    costItem3: 'currency',
    costItem4: 'feeName',
    costItem5: 'feeModel',
    costItem6: 'volume',
    costItem7: 'applyOn'
  },
  autoTransfer: {
    tableColumn1: 'Merchant Code',
    tableColumn2: 'Country',
    tableColumn3: 'Create Time',
    tableColumn4: 'Payin Account ID',
    tableColumn5: 'Payout Account ID',
    tableColumn6: 'State',
    formTitle: 'Create Auto Transfer Config',
    transferTip:
      'The maximum amount of withdrawable amount that can be transferred from the PAY_IN account to the PAY_OUT account is {m}.',
    successTip: 'Transfer successful'
  },
  kyc: {
    tableColumn1: 'ID',
    tableColumn2: 'Customer Name',
    tableColumn3: 'Phone',
    tableColumn4: 'Link',
    tableColumn5: 'Status',
    tableColumn6: 'Expiration Date',
    tableColumn7: 'Create Time',
    tableColumn8: 'Owner Team',
    tableColumn9: 'Submission Time',
    tableColumn10: 'Is it expired',
    formTitle: 'KYC',
    areaErrorTip: 'Please select Area Code',
    copySuccess: 'Link copied successfully'
  },
  productRevenue: {
    date: '@:common.date',
    merchant: 'Merchant',
    country: '@:common.country',
    businessType: 'Business Type',
    directionType: '@:common.operation',
    vendor: 'Vendor',
    product: 'Product'
  },
  businessType: {
    [BUSINESS_TRANSACTION]: 'Transaction',
    [BUSINESS_TOPUP]: 'Topup',
    [BUSINESS_REFUND]: 'Refund',
    [BUSINESS_TRANSFER_OUT]: 'Transfer out',
    [BUSINESS_EXCHANGE]: 'Exchange',
    [BUSINESS_ADJUSTMENT]: 'Adjustment',
    [BUSINESS_SUPPLEMENT]: 'Supplement'
  },
  directionType: {
    [DIRECTION_SETTLED]: 'Settle',
    [DIRECTION_REFUND]: 'Refund',
    [DIRECTION_REJECTED]: 'Rejected',
    [DIRECTION_CHARGE_BACK]: 'Charge back',
    [DIRECTION_CHARGE_BACK_REJECTED]: 'Charge back rejected'
  },
  dataCenter,
  messageCenter,
  ...enLocale
}
