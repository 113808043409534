export default {
  status: {
    pending: 'Pending',
    processing: 'Processing',
    solved: 'Solved'
  },
  solve: 'Solve',
  infoTitle1: 'Reply Information',
  infoTitle2: 'Case Information',
  infoReplyResponder: 'Responder',
  createUser: 'Create User',
  viewDetail: 'View Detail',
  chargeback: 'Charge Back',
  complaintResult: 'Complaint Result',
  refund: 'Refund',
  original: 'Original Transaction',
  originalAmount: 'Original Amount',
  refundTransaction: 'Refund Transaction',
  tableColumn1: 'Case ID',
  tableColumn2: 'Title',
  tableColumn3: 'Merchant',
  tableColumn4: 'Country',
  tableColumn6: 'Product Type',
  transactionKey: 'Transaction Key',
  tableColumn8: 'Status',
  tableColumn9: 'Create Time',
  tableColumn10: 'Solved Time',
  tableColumn11: 'Operation',
  tableColumn12: 'Description',
  appendix: 'Appendix',
  tableColumn14: 'Bank Code',
  tableColumn15: 'Bank Name',
  tableColumn16: 'Account Type',
  tableColumn17: 'Account Number',
  tableColumn18: 'Beneficiary Name',
  tableColumn19: 'Bank Branch ID',
  tableColumn20: 'Document Type',
  tableColumn21: 'Document',
  tableColumn22: 'Refund Amount'
}
