<template>
  <div
    id="app"
    :class="{ 'is-pc': isPc }"
  >
    <router-view :class="{ 'is-mobile': !isPc }" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['isPc'])
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;

  &.is-pc {
    display: flex;
    align-items: stretch;
    width: initial;
    min-width: $maxWidth;
  }

  .is-mobile {
    width: 100%;
    height: 100%;
    background-color: #f2f3f5;
    &.login {
      background-color: #fff;
    }
  }
}
</style>
