import Vue from 'vue'
import {
  Popup,
  Picker,
  Form,
  Field,
  Button,
  Checkbox,
  CheckboxGroup,
  Icon,
  Dialog,
  ActionSheet,
  Tag,
  DatetimePicker,
  Toast,
  Collapse,
  CollapseItem,
  Switch,
  Tab,
  Tabs,
  Loading,
  List,
  Cell,
  Overlay,
  Badge,
  NumberKeyboard
} from 'vant'

Vue.use(Popup)
Vue.use(Picker)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(ActionSheet)
Vue.use(Tag)
Vue.use(DatetimePicker)
Vue.use(Toast)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Switch)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(Loading)
Vue.use(List)
Vue.use(Cell)
Vue.use(Overlay)
Vue.use(Badge)
Vue.use(NumberKeyboard)
