export default {
  balance: 'Balance',
  payoutFunding: 'Payout Funding',
  withdrawal: 'Withdrawal',
  exchange: 'Exchange',
  subMerchant: 'Sub-merchant',
  withdrawableBalance: 'Withdrawable @:account.balance',
  exchangeableBalance: 'Exchangeable @:account.balance',
  requestId: 'Request ID',
  country: '@:common.country',
  accountType: 'Account Type',
  requestAmount: 'Request Amount',
  status: '@:common.status',
  createTime: '@:common.createTime',
  updateTime: '@:common.updateTime',
  operation: '@:common.operation',
  merchant: 'Merchant',
  verifiedAmount: 'Verified Amount',
  chargedTo: 'Charged To',
  wireCost: 'Wire Cost',
  product: 'Product',
  merchantReference: 'Merchant Reference',
  refundTime: 'Refund Time',
  refundAmount: 'Refund Amount',
  bankCode: 'Bank Code',
  bankName: 'Bank Name',
  bankAccountNumber: 'BankAccount Number',
  bankAccountType: 'BankAccount Type',
  bankBranchId: 'BankBranch Id',
  beneficiaryName: 'Beneficiary Name',
  feeMode: 'Fee Mode',
  subMerchantId: '@:account.subMerchant ID',
  ownerTeam: 'Owner Team',
  finalAmount: 'Final Amount',
  fundingAmount: 'Funding Amount',
  fx: 'Fx',
  progress: 'Progress',
  bankInformation: 'Account Information',
  extraCost: 'Extra Cost',
  availableBalacne: 'Available @:account.balance',
  unavailableBalacne: 'Unavailable @:account.balance',
  pendingBalacne: 'Pending @:account.balance',
  holdingAmount: 'On Hold',
  tableColumn1: 'Account ID',
  tableColumn4: 'Account Currency',
  tableColumn5: 'Exchange Rate Currency',
  tableColumn6: 'Time Zone',
  tableColumn9: 'Deposit',
  createdSuccess: 'successfully created',
  adjustment: {
    title: 'Amount Adjustment',
    label1: 'Business Type',
    label2: 'Amount',
    label3: 'Fee',
    label4: 'Tax',
    label5: 'Adjust Direction',
    label6: 'Remark',
    balance: '@:account.balance',
    label8: 'Cost Income',
    topup: 'TopUp',
    transferOut: 'Withdrawal',
    adjustment: 'Adjustment',
    reimburse: 'Reimburse',
    deduction: 'Deduction',
    confirmTip: 'Amount Estimation',
    startBalance: 'Start Balance',
    endBalance: 'End Balance',
    successTip: 'Amount Adjustment Successful'
  },
  adjustDeposit: {
    label1: 'Amount',
    label2: 'Currency',
    title: 'Deposit Adjustment',
    ensureTips: 'Confirm that the deposit is changed to ',
    successTip: 'Deposit Adjustment Successful',
    confirmTip: 'Modification Deposit'
  },
  extra: {
    title: 'Extra Income/Cost',
    title2: 'Income',
    title3: 'Cost',
    label1: 'Fee',
    label2: 'Tax',
    label3: 'Fx',
    label5: 'Fee',
    label6: 'Extra Fee',
    label7: 'Tax',
    label8: 'Extra Tax',
    label9: 'Fx',
    label10: 'Extra Fx',
    label11: 'Remark',
    label12: 'CDI Profit',
    label13: 'Business Tag',
    label14: 'Adjustment',
    label15: 'Exchange',
    successTip: 'Extra fee/cost recording successfully',
    transactionDate: 'Transaction Date'
  },
  unhold: {
    title: 'Amount Unhold',
    label1: 'Hold Amount',
    successTip: 'Unhold Successful'
  },
  accountStatus: {
    processing: 'Processing',
    completed: 'Completed',
    rejected: 'Rejected',
    canceled: 'Canceled',
    failed: 'Failed',
    inTransfer: 'In Transfer',
    paymentFailed: 'Payment Failed',
    waitProof: 'Wait Proof',
    waitExchangeRate: 'Wait FX Confim',
    waitConfirmContract: 'Verify FX'
  },
  form: {
    accountType: 'Account Type',
    amount: '@:common.amount',
    label3Placeholder: 'Please enter the amount to transfer out',
    currency: 'Currency',
    label5: 'Transfer time',
    label6: 'Remitting bank',
    label7: 'Beneficiary Account',
    proofOfTransfer: 'Proof of transfer',
    label9: 'Remark',
    bankAccountBalance: 'Bank Account Balance',
    label10: 'Notification Email',
    exchangeRate: 'Exchange Rate',
    deductionAmount: 'Deduction Amount',
    receiptAmount: 'Receipt Amount',
    transferOutFrom: 'Transfer out from',
    label15: 'Liquido Account',
    label16: 'Merchant Account',
    merchant: '@:account.merchant',
    label18: 'Topup to',
    label19: 'Legal Name',
    label20: 'Trading Name',
    label21: 'Tax ID / National ID (if natural person)',
    label22: 'Country of Incorporation / Country of Residence (if natural person)',
    label23: 'Website / App Download Link',
    label24: 'Service Applying',
    label25: 'Industry',
    label26: 'Card Applicable',
    label27: 'Funding Amount',
    typePayin: 'Payin',
    typePayout: 'Payout',
    typeOffline: 'Offline',
    online: 'Online',
    typeMarketPlace: 'MarketPlace',
    beneficiaryTip1:
      'Please transfer the top-up amount to the above account and attach the proof of transfer.',
    beneficiaryTip2:
      'Above are the default beneficiary account. If you need to change the default account, please send an email to withdrawal@liquido.com.',
    beneficiaryTip3:
      'Please transfer the exchange amount to the above account and attach the proof of transfer later.',
    deductionWithdrawableTip:
      'Withdrawable Balance: Your available balance {available} minus the deposit held for security or fees {deposit}. This is the amount you can currently withdraw from your account.',
    deductionExchangeTip:
      'Exchangeable Balance: Your available balance {available} minus the deposit held for security or fees {deposit}. This is the amount you can currently exchange from your account.',
    amountTip1: 'A small transfer fee of {x} will be charged if the requested amount is below {d}.',
    amountTip2: 'A withdrawal fee of {f} will be charged for each withdrawal transfer.',
    amountTip3: 'A Topup fee of {f} will be charged for each Topup.',
    accountTip: 'Please select beneficiary account or contact us to config.',
    customExchangeRateTip: 'Please wait for exchange rate confirm',
    smallFeeTitle: 'Small transfer fee'
  }
}
