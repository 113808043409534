<template>
  <div class="h5-date-picker">
    <span @click="openPicker">
      <slot name="trigger"></slot>
    </span>
    <van-popup
      v-model="showDatePicker1"
      position="bottom"
      :style="{ height: '50%' }"
      safe-area-inset-bottom
    >
      <van-datetime-picker
        v-model="currentDate1"
        :title="$t('search.datePlaceholder1')"
        :min-date="min"
        :max-date="max"
        :type="pickerType"
        :confirm-button-text="$t('common.confirm')"
        :cancel-button-text="$t('common.cancel')"
        @confirm="dateOnConfirm1"
        @cancel="showDatePicker1 = false"
      />
    </van-popup>
    <van-popup
      v-model="showDatePicker2"
      position="bottom"
      :style="{ height: '50%' }"
      safe-area-inset-bottom
    >
      <van-datetime-picker
        v-model="currentDate2"
        :title="$t('search.datePlaceholder2')"
        :min-date="secondMin"
        :max-date="max"
        :type="pickerType"
        :confirm-button-text="$t('common.confirm')"
        :cancel-button-text="$t('common.cancel')"
        @confirm="dateOnConfirm2"
        @cancel="showDatePicker2 = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayJs from 'dayjs'

export default {
  name: 'TheH5DatePicker',
  props: {
    defaultDate: {
      type: Array,
      default() {
        return []
      }
    },
    minDate: {
      type: Date,
      default: undefined
    },
    maxDate: {
      type: Date,
      default: undefined
    },
    pickerType: {
      type: String,
      default: 'date' // date / datetime
    },
    immediate: {
      //Load list now
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showDatePicker1: false,
      showDatePicker2: false,
      currentDate1: null,
      currentDate2: null,
      dateValue: []
    }
  },
  computed: {
    ...mapGetters('date', ['getDay']),

    max() {
      const { maxDate, getDay } = this
      return maxDate ? maxDate : new Date(getDay().valueOf())
    },

    min() {
      const { minDate, max } = this
      let t = new Date(max)
      t.setMonth(t.getMonth() - 5)
      return minDate ? minDate : t
    },

    secondMin() {
      const { currentDate1, max } = this
      let t = new Date(max)
      t.setMonth(t.getMonth() - 5)
      return currentDate1 ? currentDate1 : t
    }
  },
  watch: {},
  created() {
    this.init()
  },
  methods: {
    init() {
      this.setCurrentDate()
      if (this.immediate) {
        this.dataReset()
      }
    },
    openPicker() {
      this.setCurrentDate()
      this.showDatePicker1 = true
    },
    setCurrentDate() {
      this.currentDate1 = new Date(dayJs(this.defaultDate[0]).format('YYYY/MM/DD HH:mm:ss'))
      this.currentDate2 = new Date(dayJs(this.defaultDate[1]).format('YYYY/MM/DD HH:mm:ss'))
    },
    dateOnConfirm1() {
      this.showDatePicker1 = false
      this.showDatePicker2 = true
    },
    dateOnConfirm2() {
      this.showDatePicker2 = false
      this.dataReset('search')
    },
    dataReset(loadType = 'default') {
      // default/search/loadmore
      const { pickerType, currentDate1, currentDate2 } = this
      const format = pickerType == 'datetime' ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
      this.dateValue = [dayJs(currentDate1).format(format), dayJs(currentDate2).format(format)]
      this.$emit('getList', this.dateValue, loadType)
    }
  }
}
</script>

<style lang="scss" scoped></style>
