import Vue from 'vue'
import { dialogEventType } from './const.js'
import ConfirmBox from './ConfirmBox.vue'

const ConfirmBoxConstructor = Vue.extend(ConfirmBox)
let instance

const initInstance = () => {
  // single instance
  if (instance) {
    const { $el } = instance
    $el?.parentNode?.removeChild($el)
  }
  instance = new ConfirmBoxConstructor({
    el: document.createElement('div')
  })
  document.body.appendChild(instance.$el)
}

/**
 * options:
 * {
    singleInstance, // 默认false， true则表示同一时刻只能有一个确认弹窗
    msg,
    confirmBtnTxt,
    cancelBtnTxt
}
*/
export function LQDConfirm(options) {
  const { singleInstance } = options
  if (singleInstance && LQDConfirm._hasInstance) {
    // 如果已经有一个弹窗，则之后的弹窗全部返回取消事件，这样做没有副作用
    return Promise.resolve({ eventType: dialogEventType.cancel })
    // return
  }
  // console.log('LQDConfirm')
  singleInstance && (LQDConfirm._hasInstance = true)
  initInstance()
  if (!instance.visible) {
    Object.keys(options).forEach((key) => {
      instance[key] = options[key]
    })
    instance.visible = true
  }
  // 打开时就用一个promise把callback替换成resolve，然后在确定或者取消的时候执行callback就是resolve，所以这里没有reject
  return new Promise((resolve) => {
    instance.callback = resolve
  }).finally(() => {
    singleInstance && (LQDConfirm._hasInstance = false)
  })
}

Vue.prototype.$LQDConfirm = LQDConfirm
