import Vue from 'vue'
import store from '@/store'
import Msg from './Msg.vue'
import { PopupManager } from 'element-ui/src/utils/popup'

let MessageConstructor = Vue.extend(Msg)
let instance
// Store messages that are not currently closed
let instances = []
let seed = 1

const Msgs = function (options) {
  if (Vue.prototype.$isServer) return
  options = options || {}
  if (typeof options === 'string') {
    options = {
      msg: options
    }
  }
  // Useronclose is used to store the callback function when the user sets to close. The parameter is the closed message instance
  let userOnClose = options.onClose
  let id = 'msg_' + seed++

  // Rewrite options.onClose
  options.onClose = function () {
    Msgs.close(id, userOnClose)
  }

  // Create a message instance. At this time, the data has not been mounted. The $el is not visible at present, and the data and real DOM cannot be accessed
  instance = new MessageConstructor({
    data: options,
    store
  })
  instance.id = id

  // Manually mount an unmounted instance When the param in $mount (param) does not exist, the template will be rendered as an element outside the document, and you must insert it into the document using the native DOM API
  instance.vm = instance.$mount()

  // Insert it into the body using the native DOM API
  document.body.appendChild(instance.vm.$el)
  instance.vm.visible = true
  instance.dom = instance.vm.$el

  // css z-index层级叠加，覆盖之前已出现但还未close的message
  instance.dom.style.zIndex = PopupManager.nextZIndex()
  instances.push(instance)
  return instance.vm
}

// 组件的close方法中调用onClose再调该方法
Msgs.close = function (id, userOnClose) {
  for (let i = 0, len = instances.length; i < len; i++) {
    if (id === instances[i].id) {
      if (typeof userOnClose === 'function') {
        userOnClose(instances[i])
      }
      // 移除message实例
      instances.splice(i, 1)
      break
    }
  }
}

//关闭所有的消息提示弹窗
Msgs.closeAll = function () {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close()
  }
}

export default Msgs
