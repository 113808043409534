export default {
  // menu
  __web_home: 'Home',
  __web_sys_permissions: 'IAM',
  __web_sys_user: 'User',
  __web_sys_role: 'Role',
  __web_sys_dashboard_inner_user: 'Supper User',
  __web_support_center: 'Support Center',
  __web_support_center_case: 'Case',
  __web_support_center_info_case: 'Case Detail',
  __web_support_center_issue_buckets: 'Issue Buckets',
  __web_merchant: 'Merchants',
  __web_merchant_manage: 'Overview',
  __web_merchant_detail: 'Merchants Detail',
  __web_account: 'Accounts',
  __web_account_manage: 'Overview',
  __web_account_payout_funding: 'Payout Funding',
  __web_account_payout_funding_detail: 'Funding Detail',
  __web_account_withdraws_application: 'Withdrawal',
  __web_account_payout_withdraws_detail: 'Withdrawal Detail',
  __web_account_exchange: 'Exchange',
  __web_account_exchange_detail: 'Exchange Detail',
  __web_account_sub_merchant: 'Sub-merchant',
  __web_account_manage_sub_merchant_detail: 'Sub-merchant Detail',
  __web_account_refund: 'Refund',
  __web_account_refund_detail: 'Refund Detail',
  __web_account_manage_cost_extra_add: 'Extra',
  __web_operation: 'Operation',
  __web_operation_charge_back: 'Charge Back',
  __web_operation_charge_back_info: 'Charge Back Detail',
  __web_operation_refund: 'Refund',
  __web_operation_refund_info: 'Refund Detail',
  __web_operation_product_revenue: 'Product Revenue',
  __web_configure: 'Config',
  __web_configure_fee_config: 'Fee Config',
  __web_configure_auto_transfer: 'Auto Transfer',
  __web_configure_cost_config: 'Cost Config',
  __web_kyc: 'KYC',
  __web_kyc_manage: 'Manage',
  __web_payment: 'Payment',
  __web_payment_payout: 'Payout',
  __web_payment_payout_detail: 'Payout Detail',
  __web_data_center: 'Data Center',
  __web_data_center_summary: 'Summary',
  __web_data_center_revenue: 'Revenue',
  __web_data_center_transaction_history: '@:dataCenter.transactionHistory.title',
  __web_message_center: 'Message Center',

  __lark_home: 'Home',
  __lark_transaction_cost: 'Transaction Cost',
  __lark_merchant_config: 'Merchant Config',

  __mv_home: 'Home',

  //btn
  __web_sys_user_create: 'Add',
  __web_sys_user_modify: 'Edit',
  __web_sys_user_remove: 'Delete',
  __web_sys_user_detail: 'Detail',
  __web_sys_user_admin_reset_pwd: 'Reset Password',
  __web_sys_role_create: 'Add',
  __web_sys_role_modify: 'Edit',
  __web_sys_role_remove: 'Delete',
  __web_sys_role_detail: 'Detail',
  __web_support_center_case_detail: 'View Detail',
  __web_support_center_case_reply: 'Reply',
  __web_support_center_case_process: 'Process',
  __web_support_center_case_solve: 'Solve',
  __web_merchant_manage_modify: 'Edit',
  __web_merchant_main_account_unlock: 'Unlock',
  __web_merchant_main_account_password_reset: 'Reset Password',
  __web_merchant_merger_account_modify: 'Merger Account',
  __web_account_manage_adjust: 'Adjustment',
  __web_account_manage_unhold: 'Unhold',
  __web_account_manage_payout_funding: 'Create',
  __web_account_manage_withdrawal: 'Create',
  __web_account_manage_exchange: 'Create',
  __web_account_manage_sub_merchant: 'Create',
  __web_account_manage_deposit_update: 'Update Deposit',
  __web_operation_charge_back_create: 'Create',
  __web_operation_charge_back_reply: 'Reply',
  __web_operation_charge_back_solve: 'Solve',
  __web_operation_refund_create: 'Create',
  __web_operation_refund_reply: 'Reply',
  __web_operation_refund_solve: 'Solve',
  __web_configure_auto_transfer_create: 'Create',
  __web_configure_internal_transfer: 'Transfer',
  __web_configure_cost_config_create: 'Create',
  __web_configure_cost_config_modify: 'Edit',
  __web_configure_cost_config_remove: 'Delete',
  __web_message_center_create: 'Create',
  __web_message_center_edit: 'Edit',
  __web_message_center_delete: 'Delete'
}
