<template>
  <div class="files">
    <div
      v-for="file in fileList"
      :key="file.id"
      class="file"
    >
      <img
        v-if="imageList && imageList[file.id]"
        :src="imageList[file.id]"
        preview="1"
        :preview-text="file.fileName"
        alt=""
        :style="{
          width: size + 'px',
          height: size + 'px'
        }"
      />
      <div
        v-else
        class="file-types"
        :style="{
          width: size + 'px',
          height: size + 'px'
        }"
        @click="previewOrDownloadFile(file)"
      >
        <div class="type">
          {{ file.fileName.substr(file.fileName.lastIndexOf('.') + 1) }}
        </div>
      </div>
      <div
        class="file-name ellipsis"
        @click="previewOrDownloadFile(file)"
      >
        {{ file.fileName.substr(0, file.fileName.lastIndexOf('.')) }}
      </div>
      <div
        class="suffix"
        @click="previewOrDownloadFile(file)"
      >
        {{ file.fileName.substr(file.fileName.lastIndexOf('.')) }}
      </div>
    </div>
  </div>
</template>

<script>
import tools from '@/utils'
import fileSaver from 'file-saver'
export default {
  name: 'FileList',
  props: {
    fileList: {
      type: Array,
      default() {
        return []
      }
    },
    imageList: {
      type: Object,
      default() {
        return {}
      }
    },
    downloadFiles: {
      type: Function,
      default: undefined
    },
    size: {
      type: Number,
      default: 38
    }
  },
  data() {
    return {
      previewURLs: {}
    }
  },
  watch: {
    imageList: {
      handler() {
        this.$nextTick(() => {
          this.previewRefresh()
        })
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    // release
    const { previewURLs } = this
    for (const key in previewURLs) {
      if (Object.hasOwnProperty.call(previewURLs, key)) {
        const url = previewURLs[key]
        window.URL.revokeObjectURL(url)
      }
    }
  },
  methods: {
    previewRefresh: tools.debounce(function () {
      this.$previewRefresh()
    }, 100),
    openFullScreenLoading() {
      const { $t } = this
      tools.showLoading({
        text: $t('common.loading'),
        lock: true
      })
    },
    previewOrDownloadFile: tools.throttle(function (file) {
      this.openFullScreenLoading()
      this.downloadFiles(file)
        .then((res) => {
          if (res.size === 0) {
            tools.msg({
              msg: this.$t('common.error'),
              type: 'error'
            })
            return
          }

          // preview if pdf
          if (file.fileName.toLocaleLowerCase().endsWith('.pdf')) {
            const { previewURLs } = this
            const { id } = file
            let previewURL
            if (previewURLs[id]) {
              previewURL = previewURLs[id]
            } else {
              previewURL = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
              previewURLs[id] = previewURL
            }
            window.open(previewURL, '_blank')
          } else {
            const blob = new Blob([res], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            fileSaver.saveAs(blob, file.fileName)
          }
        })
        .finally(() => {
          tools.closeLoading()
        })
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.is-pc {
  .files {
    display: flex;
    flex-wrap: wrap;
    .file {
      display: flex;
      align-items: center;
      border-radius: 12px;
      margin: 5px 5% 5px 0;
      cursor: pointer;

      .file-types {
        position: relative;
        background-image: url('~@/assets/images/pc/case_file_back.png');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: center;
        .type {
          color: #fff;
          font-weight: 700;
          position: absolute;
          width: 90%;
          padding-left: 3px;
          box-sizing: border-box;
          height: 35%;
          text-align: left;
          line-height: 100%;
          bottom: 18%;
        }
      }

      .file-name {
        margin-left: 8px;
        max-width: 200px;
      }

      &:hover {
        color: $themeColor;
      }
    }
  }
}
</style>
