import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './lang/en'
import axios from 'axios'
import ElementLocale from 'element-ui/lib/locale'
import { EN } from '@/utils/constants'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: EN, // set default language environment
  fallbackLocale: EN,
  silentFallbackWarn: true,
  messages: {
    // set language message
    [EN]: en
  }
})

const loadedLanguages = [EN] // default lang

function setI18nLanguage(lang) {
  if (i18n.locale !== lang) {
    ElementLocale.i18n((key, value) => i18n.t(key, value))
  }
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  // same lang
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // lang had loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // lang has not been loaded
  return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/lang/${lang}/index.js`).then(
    (messages) => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}
