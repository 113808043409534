var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"files"},_vm._l((_vm.fileList),function(file){return _c('div',{key:file.id,staticClass:"file"},[(_vm.imageList && _vm.imageList[file.id])?_c('img',{style:({
        width: _vm.size + 'px',
        height: _vm.size + 'px'
      }),attrs:{"src":_vm.imageList[file.id],"preview":"1","preview-text":file.fileName,"alt":""}}):_c('div',{staticClass:"file-types",style:({
        width: _vm.size + 'px',
        height: _vm.size + 'px'
      }),on:{"click":function($event){return _vm.previewOrDownloadFile(file)}}},[_c('div',{staticClass:"type"},[_vm._v(" "+_vm._s(file.fileName.substr(file.fileName.lastIndexOf('.') + 1))+" ")])]),_c('div',{staticClass:"file-name ellipsis",on:{"click":function($event){return _vm.previewOrDownloadFile(file)}}},[_vm._v(" "+_vm._s(file.fileName.substr(0, file.fileName.lastIndexOf('.')))+" ")]),_c('div',{staticClass:"suffix",on:{"click":function($event){return _vm.previewOrDownloadFile(file)}}},[_vm._v(" "+_vm._s(file.fileName.substr(file.fileName.lastIndexOf('.')))+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }