export default {
  deliveryType: 'Delivery Type',
  content: 'Content',
  platform: 'Platform',
  systemMessageType: 'System Message Type',
  emailContentType: 'Email Content Type',
  merchants: 'Merchants',
  createSuccess: 'Successfully created!',
  editSuccess: 'Successfully edited!',
  merchantName: 'Merchant Name',
  publishTime: 'Publish Time',
  expireTime: 'Expire Time'
}
