<template>
  <div>
    <ul
      v-if="fileList.length"
      class="file-list"
    >
      <li
        v-for="item in fileList"
        :key="item.id"
      >
        <div class="file-name">{{ item.fileName }}</div>
        <div
          class="close-icon"
          @click="deleteFile(item.id)"
        >
          <i class="el-icon-close"></i>
        </div>
      </li>
    </ul>
    <el-upload
      ref="upload"
      class="upload-demo"
      action=""
      :http-request="uploadFiles"
      multiple
      drag
      :show-file-list="false"
      :disabled="fileList.length > 2 || loading"
    >
      <el-button
        size="small"
        type="primary"
        plain
        :loading="loading"
        :disabled="fileList.length > 2"
        >{{ $t('common.uploadFiles') }}<i class="el-icon-upload2 el-icon--right"></i
      ></el-button>
      <span class="upload-tip">{{ tipText || $t('case.uploadTip') }}</span>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'UploadAppendix',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    tipText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    uploadFiles(params) {
      this.$emit('uploadFiles', params)
    },
    deleteFile(id) {
      this.$emit('deleteFile', id)
    },
    clearFiles() {
      this.$refs['upload'].clearFiles()
    }
  }
}
</script>

<style lang="scss" scoped>
.is-pc {
  .file-list {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    li {
      background-color: #eee;
      border: 1px solid #dedede;
      height: 30px;
      line-height: 30px;
      margin-bottom: 10px;
      margin-right: 15px;
      display: flex;
      .file-name {
        padding: 0 15px;
        text-align: center;
        min-width: 70px;
        border-right: 1px solid #dedede;
      }
      .close-icon {
        cursor: pointer;
        padding: 0 6.5px;

        &:hover {
          color: $danger;
        }
      }
    }
  }

  .upload-demo {
    ::v-deep .el-upload {
      display: flex !important;
      align-items: center !important;
      .el-button {
        width: 150px;
        height: 36px;
      }

      .upload-tip {
        display: inline-block;
        margin-left: 20px;
        width: 200px;
        line-height: 14px;
        color: $auxiliaryFontColor;
      }

      .el-upload-dragger {
        width: 340px;
        height: 96px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .upload-tip {
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
