<template>
  <div
    ref="myCharts"
    style="height: 100%"
  ></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Echarts',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    options: {
      handler(newValue) {
        this.myChart.setOption(newValue, { notMerge: true })
      },
      deep: true
    },
    loading: {
      handler(flag) {
        this.$nextTick(() => {
          setTimeout(() => {
            // Wait for method loadEchart() to complete execution
            if (this.myChart) {
              if (flag) {
                this.myChart.showLoading({ text: '' })
              } else {
                this.myChart.hideLoading()
              }
            }
          }, 5)
        })
      },
      immediate: true
    }
  },
  created() {
    this.$nextTick(() => {
      this.loadEchart()
    })
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    if (this.myChart) {
      this.myChart.dispose()
      this.myChart = null
    }
  },
  methods: {
    handleResize() {
      if (this.myChart) this.myChart.resize()
    },
    loadEchart() {
      this.myChart = echarts.init(this.$refs['myCharts'])
      this.myChart.setOption(this.options)

      const _this = this
      this.myChart.on('legendselectchanged', function (params) {
        /**
         * params:
         * {
         *   chart: echartsInstance,
         *   type: 'legendselectchanged',
         *   // 切换的图例名称
         *   name: string
         *   // 所有图例的选中状态表
         *   selected: {
         *       [name: string]: boolean
         *   }
         */
        _this.$emit('legend-changed', { ...params, chart: _this.myChart })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.is-pc {
  .the-export {
    .el-button {
      height: 38px;
      border-radius: 8px;
    }
  }
}
</style>
