<template>
  <div class="the-search">
    <div class="the-search-operation">
      <div class="the-search-bar">
        <!-- Select which item to edit -->
        <el-select
          v-model="selectKey"
          class="option-select"
          :disabled="tableLoading"
          :no-data-text="$t('common.noData')"
          placeholder="Default"
          @change="changeType"
        >
          <el-option
            v-for="item in options"
            :key="item.prop"
            :label="item.label"
            :value="item.prop"
          />
          <template slot="prefix">{{ $t('search.options') }}:</template>
        </el-select>

        <el-input
          v-if="selectItem.type == 'input'"
          v-model="value"
          prefix-icon="el-icon-search"
          class="input-content"
          :disabled="tableLoading"
          :placeholder="$t('search.inputPlaceholder')"
        >
          <el-button
            slot="suffix"
            type="primary"
            @click="searchChange"
            >{{ $t('common.search') }}</el-button
          >
        </el-input>

        <el-select
          v-else-if="selectItem.type == 'select'"
          v-model="value"
          class="select-content"
          :disabled="tableLoading"
          :filterable="selectItem.filterable"
          :no-data-text="$t('common.noData')"
          :placeholder="$t('search.selectPlaceholder')"
          @change="searchChange"
        >
          <el-option
            v-for="(label, key) in selectItem.options"
            :key="key"
            :label="label"
            :value="key"
          />
        </el-select>

        <el-date-picker
          v-else-if="selectItem.type == 'datetime'"
          v-model="value"
          class="date-content date-single-content"
          :disabled="tableLoading"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetime"
          :placeholder="$t('search.datePlaceholder1')"
          :picker-options="pickerOptions"
          :clearable="false"
          @change="searchChange"
        />

        <el-date-picker
          v-else-if="selectItem.type == 'datetimerange'"
          v-model="value"
          class="date-content"
          :default-time="['00:00:00', '23:59:59']"
          :disabled="tableLoading"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="-"
          :start-placeholder="$t('search.datePlaceholder1')"
          :end-placeholder="$t('search.datePlaceholder2')"
          :picker-options="pickerOptions"
          :clearable="false"
          @change="searchChange"
        />

        <el-date-picker
          v-else-if="selectItem.type == 'daterange'"
          v-model="value"
          class="date-content"
          :disabled="tableLoading"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          :start-placeholder="$t('search.datePlaceholder1')"
          :end-placeholder="$t('search.datePlaceholder2')"
          :picker-options="pickerOptions"
          :clearable="false"
          @change="searchChange"
        />

        <div
          v-if="showNow"
          class="time-show-now"
          :class="{ disabled: tableLoading }"
          @click="() => $refs['datetimerange'] && $refs['datetimerange'].focus()"
        >
          {{ $t('common.now') }}
        </div>
      </div>

      <transition name="el-zoom-in-top">
        <div
          v-show="showSearchBar && Object.keys(showSearchBar).length"
          class="search-show-bar"
        >
          <el-tag
            v-for="(tag, prop) in showSearchBar"
            :key="prop"
            class="tag-bar"
            effect="plain"
            :closable="
              tag.type != 'datetime' &&
              tag.type != 'daterange' &&
              tag.type != 'datetimerange' &&
              !tableLoading
            "
            @click="changeType(prop)"
            @close="handleClose(prop)"
          >
            <span class="left">{{ tag.label }} :</span>
            <span class="right">{{ tag.value }}</span>
          </el-tag>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import dayJs from 'dayjs'

export default {
  name: 'TheSearch',
  props: {
    options: {
      type: Array,
      default() {
        return [
          // {
          //     prop: '', // key
          //     label: '',
          //     type: '',
          //     value: '', // default value
          //     options: {} // for seletor
          // }
        ]
      },
      validator(value) {
        return Array.isArray(value) && value.length
      }
    },
    tableLoading: {
      type: Boolean,
      default: true
    },
    immediate: {
      // Load list now
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectKey: '',
      selectItem: null,
      value: null,
      showNow: false, // Custom pick style
      showSearchBar: {},
      utcTime: {}
    }
  },
  computed: {
    ...mapGetters('date', ['getDay', 'utc2User', 'defaultRange', 'user2Utc']),
    ...mapState(['country', 'isPc', 'userInfo']),
    pickerOptions() {
      const today = this.getDay()
      return {
        disabledDate(time) {
          return time.getTime() > dayJs(today.format('YYYY-MM-DD') + ' 23:59:59').valueOf()
        }
      }
    },
    /**
     * Format data to key-value for search
     * @example
     *  {
     *    [prop]: value,
     *    ...
     *  }
     */
    valueObj() {
      const { options, showSearchBar } = this
      let obj = {}
      options.forEach((item) => {
        let { prop, value } = item
        if (value) {
          obj[prop] = value
        } else if (showSearchBar[prop]) {
          // After the options are updated, trigger valueObj recalculation
          // Reassign search options that already exist in showSearchBar
          obj[prop] = showSearchBar[prop].value
        } else {
          switch (item.type) {
            case this.isTimePick(item.type):
              obj[item.prop] = []
              break
            default:
              obj[item.prop] = ''
          }
        }
      })
      return obj
    },
    /**
     * Format options to the list that we need
     * @example
     *  {
     *    [prop]: {
     *      prop: '',
     *      label: '',
     *      type: '',
     *      value: '',
     *      options: {}
     *    }
     *    ...
     *  }
     */
    formatList() {
      let obj = {}
      this.options.forEach((item) => {
        obj[item.prop] = item
      })
      return obj
    },
    timeList() {
      return Object.keys(this.formatList)
        .filter((prop) => this.isTimePick(this.formatList[prop].type))
        .map((prop) => this.formatList[prop])
    }
  },
  watch: {
    'userInfo.timeZone': function () {
      this.$nextTick(() => {
        const { timeList, showSearchBar, valueObj, utcTime, utc2User } = this

        // Change time picker's value when timezone is changed
        const dateFormatModel = 'YYYY-MM-DD HH:mm:ss'
        timeList.forEach((item) => {
          const { prop, type } = item
          const value = utcTime[prop]
          const fomatTimeRange = value.length
            ? [utc2User(value[0], dateFormatModel), utc2User(value[1], dateFormatModel)]
            : []

          showSearchBar[prop] &&
            (showSearchBar[prop].value = this.formatValue(type, fomatTimeRange))
          if (this.selectItem && this.selectItem.type === type) {
            this.value = fomatTimeRange
          }
          valueObj[prop] = fomatTimeRange
        })
      })
    },
    selectItem(val) {
      this.showNow = false
      if (!val) return

      const { type, prop } = val
      if (type === 'datetimerange') {
        const value = this.valueObj[prop]
        if (value.length && this.isEndDateNow(value[1])) {
          this.value = value
          this.showNow = true
        }
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    isTimePick(type) {
      return ['datetime', 'datetimerange', 'daterange'].includes(type)
    },
    init() {
      const { valueObj, formatList, timeList, showSearchBar, immediate, user2Utc } = this

      // Init show bar
      Object.keys(valueObj).forEach((prop) => {
        let { label, value, type } = formatList[prop]
        if (value) {
          showSearchBar[prop] = {
            label,
            value: this.formatValue(type, value),
            type
          }
        }
      })

      // Init utcTime
      timeList.forEach((item) => {
        const { prop } = item
        const value = valueObj[prop]
        this.utcTime[prop] = value.length ? [user2Utc(value[0]), user2Utc(value[1])] : []
      })

      this.changeType(this.options[0].prop)
      this.$forceUpdate()
      if (immediate) {
        this.dataReset()
      }
    },
    /**
     * Change current search item
     */
    changeType(key) {
      this.value = this.valueObj[key]
      this.selectItem = this.formatList[key]
      this.selectKey = key
    },
    /**
     * Change current search item value
     */
    searchChange(val) {
      if (!this.selectItem) return

      const { user2Utc, defaultRange, selectItem } = this
      const { type, label, prop } = selectItem

      // Handle date value
      if (this.isTimePick(type)) {
        if (val) {
          this.utcTime[prop] = [user2Utc(val[0]), user2Utc(val[1])]
        }
        if (!this.value) {
          this.value = defaultRange()
          this.utcTime[prop] = [user2Utc(this.value[0]), user2Utc(this.value[1])]
        }
      }

      let { value, valueObj, showSearchBar } = this
      valueObj[prop] = value

      this.showNow = type === 'datetimerange' && value.length && this.isEndDateNow(value[1])

      // Value for bar to show
      let el = {
        label,
        value: this.formatValue(type, value),
        type
      }
      if (el.value) {
        showSearchBar[prop] ? (showSearchBar[prop].value = el.value) : (showSearchBar[prop] = el)
      } else {
        delete showSearchBar[prop]
      }

      this.$forceUpdate()
      this.dataReset('search')
    },
    /**
     * Format value for show bar
     */
    formatValue(type, value) {
      let temp = value
      switch (true) {
        case this.isTimePick(type):
          if (value.length) {
            temp = value[0] + ' - ' + value[1]

            // If the end time is greater than 2099 year
            if (type === 'datetimerange' && new Date(value[1]).getTime() > 4070880000000) {
              temp = value[0] + ' - ' + this.$t('common.now')
            }
          }
          break
        case type === 'select':
          if (value.length) {
            temp = this.selectItem.options?.[value]
          }
          break
      }
      return temp
    },
    /**
     * Whether use 'now' to show end date
     */
    isEndDateNow(endDate) {
      return endDate && new Date(endDate).getTime() > 4070880000000
    },
    handleClose(prop) {
      const { showSearchBar, valueObj } = this
      delete showSearchBar[prop]
      this.$forceUpdate()
      valueObj[prop] = Array.isArray(valueObj[prop]) ? [] : ''
      this.changeType(prop)
      this.dataReset('search')
    },
    /**
     * Get list
     * type loadType = 'default' | 'search' | 'loadmore'
     */
    dataReset(loadType = 'default') {
      this.$emit('getList', this.valueObj, loadType)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-pc {
  .the-search {
    display: flex;
    justify-content: space-between;
    padding-bottom: 13px;

    $height: 38px;

    .the-search-operation {
      .the-search-bar {
        display: flex;
        margin-bottom: 12px;
        ::v-deep .option-select {
          flex-shrink: 0;
          margin-right: 12px;
          width: 230px;
          height: $height;
          .el-input {
            height: 100%;
            input {
              border-radius: 8px;
            }
            .el-input__inner {
              height: 100% !important;
              text-align: right;
              color: $themeFontColor;
              padding-left: 70px;
            }
            .el-input__prefix {
              left: 12px;
              width: 50px;
              color: $themeFontColor;
            }
          }
        }
        ::v-deep .input-content {
          width: 420px;
          height: $height;
          padding: 0 5px;
          border-radius: 8px;
          box-sizing: border-box;
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .el-button {
            height: 30px;
            border-radius: 6px;
          }
          .el-input__suffix {
            width: 78px;
          }
          input {
            border: none;
          }
        }
        ::v-deep .select-content {
          flex-shrink: 0;
          .el-input {
            height: 100%;
            input {
              border-radius: 8px;
              padding-left: 12px;
            }
            .el-input__inner {
              height: 100% !important;
              color: $themeFontColor;
            }
          }
        }

        .time-show-now {
          position: relative;
          top: 2px;
          left: -133px;
          z-index: 100;
          width: 122px;
          height: 34px;
          line-height: 34px;
          color: #606266;
          text-align: center;
          background-color: #fff;

          &.disabled {
            background-color: #f5f7fa;
          }
        }

        ::v-deep .date-content {
          flex-shrink: 0;
          border-radius: 8px;
          width: 310px;
          height: $height;

          .el-input__inner {
            height: 100%;
            border-radius: 8px;
          }

          .el-range-input {
            width: 45%;
          }

          .el-range-separator {
            line-height: calc($height - 8px);
          }

          .el-icon-time {
            line-height: calc($height - 8px);
          }

          .el-range__close-icon {
            width: 0 !important;
          }
        }

        ::v-deep .date-single-content {
          width: 230px;
          .el-icon-time {
            line-height: $height;
          }
        }
      }
      .search-show-bar {
        display: flex;
        flex-wrap: wrap;
        .tag-bar {
          cursor: pointer;
          height: 26px;
          line-height: 26px;
          padding: 0 12px;
          margin-right: 12px;
          margin-bottom: 12px;
          border: none;
          border-radius: 6px;
          .left {
            color: $themeFontColor;
          }
          .right {
            color: #7d7f98;
            margin-left: 12px;
          }
        }
      }
    }
  }
}
</style>
