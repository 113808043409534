<template>
  <div>
    <el-dialog
      v-if="isPc"
      :visible.sync="visible"
      width="420px"
      @close="close"
    >
      <template v-if="type == 'info'"></template>
      <template v-else-if="type == 'success'">
        <img src="@/assets/images/pc/access_success.png" />
        <span>{{ $t('common.success') }}</span>
      </template>
      <template v-else-if="type == 'error'">
        <img src="@/assets/images/pc/access_error.png" />
        <span>{{ $t('common.error') }}</span>
      </template>
      <p>{{ msg }}</p>
      <el-button
        v-if="type != 'info'"
        type="primary"
        @click="close"
        >{{ $t('common.ok') }}</el-button
      >
    </el-dialog>
    <van-dialog
      v-else
      v-model="visible"
      width="300px"
      :close-on-click-overlay="true"
      :show-confirm-button="type != 'info'"
      :confirm-button-text="$t('common.done')"
      @confirm="close"
    >
      <template v-if="type == 'info'"></template>
      <template v-else-if="type == 'success'">
        <img src="@/assets/images/pc/access_success.png" />
        <span>{{ $t('common.success') }}</span>
      </template>
      <template v-else-if="type == 'error'">
        <img src="@/assets/images/pc/access_error.png" />
        <span>{{ $t('common.error') }}</span>
      </template>
      <p>{{ msg }}</p>
    </van-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Msg',
  data() {
    return {
      visible: false,
      type: 'info',
      msg: '',
      duration: 0,
      t: null,
      // callback function when closing
      onClose: null,
      closed: false
    }
  },
  computed: {
    ...mapState({
      root: (state) => state.vm
    }),
    ...mapState(['isPc']),
    $t() {
      return this.root.$t
    }
  },
  watch: {
    // Is message off
    closed(newVal) {
      if (newVal) {
        this.visible = false
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.keydown)
    const { duration } = this
    if (duration) {
      let _this = this
      this.t = setTimeout(() => {
        _this.close()
        clearTimeout(_this.t)
      }, duration)
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keydown)
    if (this.t) {
      clearTimeout(this.t)
    }
  },
  methods: {
    // After leaving
    handleAfterLeave() {
      this.$destroy(true)
      this.$el.parentNode.removeChild(this.$el)
    },

    close() {
      this.closed = true
      if (typeof this.onClose === 'function') {
        this.onClose(this)
      }
    },

    keydown(e) {
      // Esc close message
      if (e.keyCode === 27) {
        if (!this.closed) {
          this.close()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-dialog {
  .van-dialog__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32.5px 0;
    box-sizing: border-box;
    img {
      width: 43px;
      height: 43px;
    }

    & > span {
      margin: 16px 0 10px 0;
      font-size: 24px;
      line-height: 24px;
      font-weight: 500;
      color: #131414;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #909399;
    }
  }
}
::v-deep .el-dialog {
  margin-top: 30vh !important;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32.5px 20px;
    box-sizing: border-box;

    img {
      width: 63px;
      height: 63px;
    }

    & > span {
      margin: 17.5px 0 4px 0;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: $themeFontColor;
    }

    p {
      font-size: 14px;
      line-height: 24px;
      color: $auxiliaryFontColor;
      text-align: center;
      white-space: pre-line;
    }

    .el-button {
      margin-top: 48.5px;
      width: 68px;
      height: 32px;
      font-size: 14.5px;
    }
  }
}
</style>
