<template>
  <div class="container">
    <div class="options-container">
      <ul>
        <li
          v-for="selectItem in tempOptions"
          :key="selectItem.prop"
          :class="{
            'datetimerange-container': selectItem.type == 'datetimerange',
            'is-required': selectItem.isRequired
          }"
        >
          <label>{{ selectItem.label }}：</label>

          <el-input
            v-if="selectItem.type == 'input'"
            v-model="selectItem.value"
            prefix-icon="el-icon-search"
            clearable
            class="search-item input-content"
            :disabled="tableLoading"
            :placeholder="$t('search.inputPlaceholder')"
            @change="(value) => valChange(value, selectItem)"
          />

          <el-select
            v-else-if="selectItem.type == 'select'"
            v-model="selectItem.value"
            filterable
            class="search-item select-content"
            clearable
            :disabled="tableLoading"
            :no-data-text="$t('common.noData')"
            :placeholder="$t('search.selectPlaceholder')"
            @change="(value) => valChange(value, selectItem)"
          >
            <el-option
              v-for="(label, key) in selectItem.options"
              :key="key"
              :disabled="tableLoading"
              :label="label"
              :value="key"
            />
          </el-select>

          <el-select
            v-else-if="selectItem.type == 'multiple-select'"
            v-model="selectItem.value"
            filterable
            class="search-item select-content multiple-search-item"
            clearable
            :disabled="tableLoading"
            :no-data-text="$t('common.noData')"
            :placeholder="$t('search.selectPlaceholder')"
            multiple
            collapse-tags
            @change="(value) => valChange(value, selectItem)"
          >
            <template v-if="selectItem.options.length > 0">
              <el-option
                v-for="item in selectItem.options"
                :key="item.value"
                :disabled="tableLoading"
                :label="item.label"
                :value="item.value"
              />
            </template>
            <template v-else>
              <el-option
                v-for="(label, key) in selectItem.options"
                :key="key"
                :disabled="tableLoading"
                :label="label"
                :value="key"
              />
            </template>
          </el-select>

          <el-select
            v-else-if="selectItem.type == 'select-deep'"
            v-model="selectItem.value"
            class="search-item select-content"
            :class="{ 'search-item': true, 'select-content': true, required: !selectItem.value }"
            clearable
            :disabled="tableLoading"
            :no-data-text="$t('common.noData')"
            :placeholder="$t('search.selectPlaceholder')"
            @change="(value) => valChange(value, selectItem)"
          >
            <el-option
              v-for="(item, key) in selectItem.options"
              :key="key"
              :disabled="tableLoading"
              :label="item.label"
              :value="key"
            />
          </el-select>

          <el-date-picker
            v-else-if="selectItem.type == 'datetime'"
            v-model="selectItem.value"
            class="search-item date-content date-single-content"
            :disabled="tableLoading"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            :placeholder="$t('search.datePlaceholder1')"
            :picker-options="pickerOptions(selectItem)"
            :clearable="false"
            @change="(value) => valChange(value, selectItem)"
            @blur="choiceDate = null"
          />

          <el-date-picker
            v-else-if="selectItem.type == 'datetimerange'"
            ref="advanceDatetimerange"
            v-model="selectItem.value"
            class="search-item date-content"
            :default-time="['00:00:00', '23:59:59']"
            :disabled="tableLoading"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="-"
            :start-placeholder="$t('search.datePlaceholder1')"
            :end-placeholder="$t('search.datePlaceholder2')"
            :picker-options="pickerOptions(selectItem)"
            :clearable="false"
            @blur="choiceDate = null"
            @change="(value) => valChange(value, selectItem)"
          />

          <el-date-picker
            v-else-if="selectItem.type == 'daterange'"
            v-model="selectItem.value"
            class="search-item date-content"
            :disabled="tableLoading"
            value-format="yyyy-MM-dd"
            :format="selectItem.format"
            type="daterange"
            range-separator="-"
            :start-placeholder="$t('search.datePlaceholder1')"
            :end-placeholder="$t('search.datePlaceholder2')"
            :picker-options="pickerOptions(selectItem)"
            :clearable="!!selectItem.clearable"
            @blur="choiceDate = null"
            @change="(value) => valChange(value, selectItem)"
          />

          <el-date-picker
            v-else-if="selectItem.type == 'month'"
            v-model="selectItem.value"
            class="search-item date-content"
            :disabled="tableLoading"
            type="month"
            :value-format="selectItem.format || 'yyyy-MM'"
            :picker-options="pickerOptions(selectItem)"
            :clearable="!!selectItem.clearable"
            :placeholder="$t('search.monthPlaceholder')"
            @change="(value) => valChange(value, selectItem)"
          />

          <el-date-picker
            v-else-if="selectItem.type == 'year'"
            v-model="selectItem.value"
            class="search-item date-content"
            :disabled="tableLoading"
            type="year"
            value-format="yyyy"
            :picker-options="pickerOptions(selectItem)"
            :clearable="!!selectItem.clearable"
            :placeholder="$t('search.yearPlaceholder')"
            @change="(value) => valChange(value, selectItem)"
          />

          <div
            v-if="selectItem.type == 'datetimerange' && showNow"
            class="time-show-now"
            :class="{ disabled: tableLoading }"
            @click="$refs.advanceDatetimerange[0].focus()"
          >
            {{ $t('common.now') }}
          </div>
        </li>
      </ul>
      <ul>
        <li
          v-for="selectItem in deepSelection"
          :key="selectItem.prop"
          :class="{ 'is-required': selectItem.isRequired }"
        >
          <label>{{ selectItem.label }}：</label>

          <el-input
            v-if="selectItem.type == 'input'"
            v-model="selectItem.value"
            prefix-icon="el-icon-search"
            clearable
            class="search-item input-content"
            :disabled="tableLoading"
            :placeholder="$t('search.inputPlaceholder')"
            @change="(value) => valChange(value, selectItem)"
          />

          <el-select
            v-else-if="selectItem.type == 'select'"
            v-model="selectItem.value"
            filterable
            class="search-item select-content"
            clearable
            :disabled="tableLoading"
            :no-data-text="$t('common.noData')"
            :placeholder="$t('search.selectPlaceholder')"
            @change="(value) => valChange(value, selectItem)"
          >
            <el-option
              v-for="(label, key) in selectItem.options"
              :key="key"
              :label="label"
              :value="key"
            />
          </el-select>

          <el-select
            v-else-if="selectItem.type == 'select-deep'"
            v-model="selectItem.value"
            class="search-item select-content"
            clearable
            :disabled="tableLoading"
            :no-data-text="$t('common.noData')"
            :placeholder="$t('search.selectPlaceholder')"
            @change="(value) => valChange(value, selectItem)"
          >
            <el-option
              v-for="(item, key) in selectItem.options"
              :key="key"
              :label="item.label"
              :value="key"
            />
          </el-select>

          <el-date-picker
            v-else-if="selectItem.type == 'datetime'"
            v-model="selectItem.value"
            class="search-item date-content date-single-content"
            :disabled="tableLoading"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            :placeholder="$t('search.datePlaceholder1')"
            :picker-options="pickerOptions(selectItem)"
            :clearable="false"
            @blur="choiceDate = null"
            @change="(value) => valChange(value, selectItem)"
          />

          <el-date-picker
            v-else-if="selectItem.type == 'datetimerange'"
            v-model="selectItem.value"
            class="search-item date-content"
            :default-time="['00:00:00', '23:59:59']"
            :disabled="tableLoading"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="-"
            :start-placeholder="$t('search.datePlaceholder1')"
            :end-placeholder="$t('search.datePlaceholder2')"
            :picker-options="pickerOptions(selectItem)"
            :clearable="false"
            @change="(value) => valChange(value, selectItem, 'deepSelection')"
          />

          <el-date-picker
            v-else-if="selectItem.type == 'daterange'"
            v-model="selectItem.value"
            class="search-item date-content"
            :disabled="tableLoading"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            :start-placeholder="$t('search.datePlaceholder1')"
            :end-placeholder="$t('search.datePlaceholder2')"
            :picker-options="pickerOptions(selectItem)"
            :clearable="!!selectItem.clearable"
            @blur="choiceDate = null"
            @change="(value) => valChange(value, selectItem)"
          />
        </li>
      </ul>
    </div>

    <div
      v-if="needSearchBtn && showSearchBtn"
      class="btns"
    >
      <el-button
        type="primary"
        :disabled="tableLoading"
        @click="search"
        >{{ $t('common.search') }}</el-button
      >
    </div>
    <div
      v-if="needDownloadBtn && showDownloadBtn"
      class="btns"
    >
      <el-button
        type="primary"
        @click="download"
        >{{ $t('common.download') }}</el-button
      >
    </div>

    <div class="btns">
      <slot name="btns"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayJs from 'dayjs'

export default {
  name: 'TheSearchList2',
  props: {
    options: {
      type: Array,
      default() {
        return []
      },
      validator(value) {
        return Array.isArray(value) && value.length
      }
    },
    tableLoading: {
      type: Boolean,
      default: true
    },
    immediate: {
      //Load list now
      type: Boolean,
      default: true
    },
    needSearchBtn: {
      type: Boolean,
      default: false
    },
    showSearchBtn: {
      type: Boolean,
      default: true
    },
    needDownloadBtn: {
      type: Boolean,
      default: false
    },
    showDownloadBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tempOptions: null,
      deepSelection: [],
      showNow: false,
      choiceDate: null
    }
  },
  computed: {
    ...mapGetters('date', ['getDay']),

    valueObj() {
      const { tempOptions } = this
      let obj = {}
      tempOptions &&
        tempOptions.forEach((el) => {
          if (el.value) {
            obj[el.prop] = el.value
          }
        })
      return obj
    },

    otherValueObj() {
      const { deepSelection } = this
      let obj = {}
      deepSelection &&
        deepSelection.forEach((el) => {
          if (el.value) {
            obj[el.prop] = el.value
          }
        })
      return obj
    }
  },
  watch: {
    options: {
      handler(newValue) {
        this.tempOptions = JSON.parse(JSON.stringify(newValue))
      },
      immediate: true
    }
  },
  created() {
    this.init()
  },
  methods: {
    pickerOptions(config) {
      const _this = this
      const today = this.getDay()
      return {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate = minDate.getTime()
          if (maxDate) {
            this.choiceDate = null
          }
        },
        disabledDate(time) {
          // if (item.range) {
          //     return time.getTime() > today.valueOf() || time.getTime() < new Date(item.range).getTime()
          // } else {
          //     return time.getTime() > today.valueOf()
          // }
          // First choice
          if (config.allTime) return false
          if (config.range) {
            const { choiceDate, today } = _this
            if (choiceDate) {
              // get seven days ago && seven days later
              const minTime = dayJs(choiceDate).subtract(config.range, 'day').valueOf()
              const maxTime = dayJs(choiceDate).add(config.range, 'day').valueOf()
              // Up to 14 days && Before today
              return (
                time.getTime() < minTime ||
                time.getTime() > maxTime ||
                time.getTime() > dayJs(today.format('YYYY-MM-DD') + ' 23:59:59').valueOf()
              )
            }
          }
          // Before today
          return time.getTime() > dayJs(today.format('YYYY-MM-DD') + ' 23:59:59').valueOf()
        }
      }
    },
    init() {
      if (this.immediate) {
        this.dataReset()
      }
    },

    valChange(value, item, optionsType = 'tempOptions') {
      const { type } = item
      if (type === 'datetimerange' && optionsType === 'tempOptions') {
        this.showNow = false
        if (!value) {
          const selectObj = this.options.filter((o) => o.prop == item.prop)[0]
          item.value = [selectObj.value[0], '2100-01-01 00:00:00']
          this.showNow = true
        }
      }
      if (type === 'select-deep') {
        this.deepSelection = item && item.value ? item.options[item.value].deepSelection : []
      }
      if (this.needSearchBtn === false) {
        this.search()
      }
    },

    search() {
      let flag = true
      this.tempOptions.forEach((el) => {
        if (el.type == 'select-deep' && !el.value) {
          flag = false
        }
      })
      if (flag) {
        this.dataReset('search')
      }
    },

    download() {
      this.$emit('download', {
        ...this.valueObj,
        other: {
          ...this.otherValueObj
        }
      })
    },

    dataReset(loadType = 'default') {
      // default/search/loadmore
      this.$emit(
        'getList',
        {
          ...this.valueObj,
          other: {
            ...this.otherValueObj
          }
        },
        loadType
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.is-pc {
  .container {
    width: 100%;
    display: flex;
    background-color: #fff;
    padding: 5px 25px;
    border-radius: 8px;
    margin-bottom: 25px;
    box-sizing: border-box;

    .options-container {
      flex: 1;

      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        li {
          margin-right: 40px;
          padding: 10px 0;
          display: flex;
          align-items: center;

          label {
            color: $themeFontColor;
            margin-right: 10px;
            font-weight: 700;
            white-space: nowrap;

            .require {
              color: $danger;
            }
          }

          .search-item {
            height: 38px !important;

            ::v-deep .el-input__inner {
              height: 38px !important;
              border-radius: 5px;
            }

            ::v-deep .el-tag {
              max-width: 50%;
            }
          }

          .multiple-search-item {
            width: 240px;
          }

          &.datetimerange-container {
            position: relative;

            .time-show-now {
              width: 122px;
              height: 34px;
              line-height: 34px;
              color: #606266;
              background-color: #fff;
              position: absolute;
              top: 12px;
              right: 58px;
              text-align: center;
              z-index: 100;

              // &.disabled {
              // }
            }
          }

          .required {
            ::v-deep .el-input__inner {
              border: 1px solid $danger;
            }
          }
        }

        /* .is-required {
                    ::v-deep>label:before {
                        content: "*";
                        color: #f56c6c;
                        margin-right: 4px;
                    }
                } */
        .is-required > label:before {
          content: '*';
          color: #f56c6c;
          margin-right: 4px;
        }
      }
    }

    .btns {
      height: 58px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      ::v-deep .el-button {
        height: 36px;
        border-radius: 5px;
      }
    }
  }
}
</style>
