<template>
  <div class="the-pagination">
    <div class="total">{{ $t('pagination.total', { n: total }) }}</div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="pageNo"
      :disabled="disabled"
      @current-change="changePage"
    />
  </div>
</template>

<script>
import { PAGE_SIZE } from '@/utils/constants'

export default {
  name: 'ThePagination',
  props: {
    total: {
      type: Number,
      default: () => 0
    },
    pageNo: {
      type: Number,
      default: () => 1
    },
    pageSize: {
      type: Number,
      default: () => PAGE_SIZE
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    changePage(pageNo) {
      this.$emit('change', pageNo)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-pc {
  .the-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;

    .total {
      font-size: 12px;
      line-height: 20.5px;
      color: #00000080;
    }

    $paginationColor: #00000099;

    ::v-deep .el-pagination {
      &.is-background .el-pager li {
        font-weight: 500;
        color: $paginationColor;
        background-color: #fff;
        border: 1px solid #dcdcdc;

        &:not(.disabled).active {
          color: #fff;
          background-color: $themeColor;
        }

        &.more {
          border: none;
        }
      }

      button {
        color: $paginationColor;
        background-color: inherit;

        &:disabled {
          color: #c0c4cc;
        }
      }
    }
  }
}
</style>
